<template>
  <base-view-layout>
    <template #breadcrumb>
      <li class="breadcrumb__nav-item breadcrumb__nav-item--back">
        <router-link :to="{ name: 'Activities' }">
          <img src="@/assets/images/icons/ico-chev-left--70L.svg">
        </router-link>
      </li>
      <li class="breadcrumb__nav-item">
        <router-link :to="{ name: 'Activities' }">{{$t('activitiesnew.breadcrumb')}}</router-link>
      </li>
      <li class="breadcrumb__nav-item">{{$t('activitiesnew.breadcrumbnavitem')}}</li>
    </template>

    <template #title>{{$t('activitiesnew.titlecreate')}}</template>
    <template #subtitle>{{$t('activitiesnew.subtitlecreate')}}</template>

    <activities-form class="main-section" @submit-activity="createActivity($event)">
      <template #finishButton>{{$t('activitiesnew.btncreate')}}</template>
    </activities-form>
  </base-view-layout>
</template>

<script>
import { inject } from 'vue'
import ActivitiesForm from '@/components/ActivitiesForm'
import BaseViewLayout from '@/components/BaseViewLayout'

export default {
  name: 'ActivitiesNew',
  components: { BaseViewLayout, ActivitiesForm },
  methods: {
    async createActivity (activity) {
      activity.questions = activity.questions.map(question => question.id)
      // activity.is_public = this.$store.state.user.role_id === 1
      await this.axios.post('activities', { activity })
      alert(this.$t('activitiesnew.successmsg'))
      this.$router.push({ name: 'Activities' })
    }
  },
  setup () {
    const axios = inject('$axios')
    return {
      axios
    }
  }
}
</script>
